<template>
  <HeaderItem />
  <section id="center" class="center_home">
    <div class="banner">
      <div
        id="kb"
        class="carousel kb_elastic animate_text kb_wrapper"
        data-ride="carousel"
        data-interval="6000"
        data-pause="hover"
      >
        <div class="carousel-inner" role="listbox">
          <div class="item active">
            <img src="img/home1.jpeg" alt="" class="img-responsive" />
            <div class="carousel-caption kb_caption kb_caption_right">
              <h1 data-animation="animated flipInX" class="">
                Interdependent Culture
              </h1>
              <p data-animation="animated flipInX" class="">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the when an unknown printer took a galley of
                type and scrambled [...]
              </p>
              <h4>
                <a href="#" class="button hvr-shutter-out-horizontal"
                  >Read More</a
                >
              </h4>
            </div>
          </div>
          <div class="item">
            <img src="img/home2.jpeg" alt="" class="img-responsive" />
            <div class="carousel-caption kb_caption kb_caption_right">
              <h1 data-animation="animated fadeInDown">
                We Provide Our best Service
              </h1>
              <p data-animation="animated fadeInUp">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the when an unknown printer took a galley of
                type and scrambled [...]
              </p>
              <h4>
                <a href="#" class="button hvr-shutter-out-horizontal"
                  >Read More</a
                >
              </h4>
            </div>
          </div>
          <div class="item">
            <img src="img/home3.jpeg" alt="" class="img-responsive" />
            <div class="carousel-caption kb_caption kb_caption_right">
              <h1 data-animation="animated fadeInDown">Organic Agriculture</h1>
              <p data-animation="animated fadeInUp">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the when an unknown printer took a galley of
                type and scrambled [...]
              </p>
              <h4>
                <a href="#" class="button hvr-shutter-out-horizontal"
                  >Read More</a
                >
              </h4>
            </div>
          </div>
        </div>
        <a
          class="left carousel-control kb_control_left"
          href="#kb"
          role="button"
          data-slide="prev"
        >
          <span class="fa fa-angle-left kb_icons" aria-hidden="true"></span>
          <span class="sr-only">Previous</span>
        </a>
        <a
          class="right carousel-control kb_control_right"
          href="#kb"
          role="button"
          data-slide="next"
        >
          <span class="fa fa-angle-right kb_icons" aria-hidden="true"></span>
          <span class="sr-only">Next</span>
        </a>
      </div>
    </div>
  </section>
  <section id="middle">
    <div class="container">
      <div class="row">
        <div class="middle_1">
          <h3>Step into the World of Organics!</h3>
          <h2><a href="#">Let the first step</a> be firmly anchored!</h2>
          <p>
            Organic farming offers an alternative agricultural system. It
            initially began in the early in the 20th century. The advent of<br />
            pesticides brought in its benefits and pitfalls simultaneously. It
            is more healthy relying on compost manure, green<br />
            manure, and bone meal.
          </p>
        </div>
        <div class="middle_2 clearfix">
          <div class="col-sm-4">
            <div class="middle_3">
              <a href="#"
                ><img
                  src="img/agriculture1.jpeg"
                  alt="abc"
                  class="img_responsive"
              /></a>
              <h3>Organic Agriculture</h3>
              <p>
                Lorem ipsum dolor sit amet, consectuerter adipiscing elit diam,
                sed diam nonummy nibh euismod tincidunt.
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="middle_3">
              <a href="#"
                ><img
                  src="img/agriculture2.jpeg"
                  alt="abc"
                  class="img_responsive"
              /></a>
              <h3>Increased Biodiversity</h3>
              <p>
                Lorem ipsum dolor sit amet, consectuerter adipiscing elit diam,
                sed diam nonummy nibh euismod tincidunt.
              </p>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="middle_3">
              <a href="#"
                ><img
                  src="img/agriculture3.jpeg"
                  alt="abc"
                  class="img_responsive"
              /></a>
              <h3>Interdependent Culture</h3>
              <p>
                Lorem ipsum dolor sit amet, consectuerter adipiscing elit diam,
                sed diam nonummy nibh euismod tincidunt.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="offers">
    <div class="offers_1 clearfix">
      <div class="col-sm-6 space_all">
        <div class="offers_main clearfix">
          <div class="offers_2 clearfix">
            <h3>Special Offers</h3>
            <h2>GET <a href="#">30%</a> OFF</h2>
            <p>YOUR ORDER OF $100 OR MORE</p>
            <div class="offers_3 clearfix">
              <div class="col-sm-3">
                <div class="offers_4">
                  <h2>240</h2>
                  <p>Days</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="offers_4">
                  <h2>6</h2>
                  <p>Hours</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="offers_4">
                  <h2>02</h2>
                  <p>Minutes</p>
                </div>
              </div>
              <div class="col-sm-3">
                <div class="offers_4">
                  <h2>40</h2>
                  <p>Seconds</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 space_all">
        <div class="Offers_2">
          <a href="#"
            ><img src="img/offer1.jpeg" alt="abc" class="img_responsive"
          /></a>
        </div>
      </div>
    </div>
  </section>
  <section id="our">
    <div class="container">
      <div class="row">
        <div class="our_1 clearfix">
          <div class="col-sm-4">
            <div class="our_2">
              <a href="#"
                ><img src="img/farming.jpeg" alt="abc" class="img_responsive"
              /></a>
              <h3>DUCK FARMING</h3>
              <p>
                Donec nec justo eget felis facilisis ferme ntum. Aliquam
                porttitor mauris sit amet orci. Aenean dignissim pellentesque
                felis. Morbi in sem quis dui placerat ornare.
              </p>
            </div>
            <div class="our_3">
              <i class="fa fa-tree"></i>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="our_2">
              <a href="#"
                ><img src="img/apple.jpeg" alt="abc" class="img_responsive"
              /></a>
              <h3>ORGANIC APPLES</h3>
              <p>
                Donec nec justo eget felis facilisis ferme ntum. Aliquam
                porttitor mauris sit amet orci. Aenean dignissim pellentesque
                felis. Morbi in sem quis dui placerat ornare.
              </p>
            </div>
            <div class="our_3">
              <i class="fa fa-dribbble"></i>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="our_2">
              <a href="#"
                ><img src="img/eggs.jpeg" alt="abc" class="img_responsive"
              /></a>
              <h3>FARMFRESH EGGS</h3>
              <p>
                Donec nec justo eget felis facilisis ferme ntum. Aliquam
                porttitor mauris sit amet orci. Aenean dignissim pellentesque
                felis. Morbi in sem quis dui placerat ornare.
              </p>
            </div>
            <div class="our_3">
              <i class="fa fa-users"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="facts">
    <div class="container">
      <div class="row">
        <div class="facts_3">
          <h3>All News Around Us</h3>
          <h2><a href="#">Our</a> Blog</h2>
        </div>
        <div class="facts_1 clearfix">
          <div class="col-sm-4">
            <div class="facts_2">
              <a href="#"
                ><img src="img/bloghome1.jpeg" alt="abc" class="img-responsive"
              /></a>
              <h3>Gallery With Images</h3>
              <ul>
                <li class="date">June 7, 2014</li>
                <li>!</li>
                <li>Sergey Kosenko</li>
              </ul>
              <p>
                Over the course of my career, I have had the opportunity to lead
                various Web design and development teams, including
              </p>
              <a href="#" class="button_1">More</a>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="facts_2">
              <a href="#"
                ><img src="img/bloghome2.jpeg" alt="abc" class="img-responsive"
              /></a>
              <h3>Fullwidth Image Post</h3>
              <ul>
                <li class="date">June 7, 2014</li>
                <li>!</li>
                <li>Sergey Kosenko</li>
              </ul>
              <p>
                Over the course of my career, I have had the opportunity to lead
                various Web design and development teams, including
              </p>
              <a href="#" class="button_1">More</a>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="facts_2">
              <a href="#"
                ><img src="img/bloghome3.jpeg" alt="abc" class="img-responsive"
              /></a>
              <h3>Beautiful new dress. Sale!</h3>
              <ul>
                <li class="date">June 7, 2014</li>
                <li>!</li>
                <li>Sergey Kosenko</li>
              </ul>
              <p>
                Over the course of my career, I have had the opportunity to lead
                various Web design and development teams, including
              </p>
              <a href="#" class="button_1">More</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="people" class="clearfix">
    <div class="people_1 clearfix">
      <div class="col-sm-6 space_all">
        <div class="people_main_1 clearfix">
          <div class="people_4">
            <h4>Call Back</h4>
            <h2><a href="#">Donation</a> Request</h2>
            <p>
              consetetur sadipscing elitr, sed diam nonumy eirmod tempor
              invidunt ut labore edolore magna aliquyam erat,
            </p>
          </div>
          <div class="people_5 clearfix">
            <div class="col-sm-6 space_all">
              <div class="people_6">
                <input type="text" class="form-control" placeholder="Value" />
              </div>
            </div>
            <div class="col-sm-6 space_all">
              <div class="people_6">
                <input type="text" class="form-control" placeholder="Email" />
              </div>
            </div>
          </div>
          <div class="people_5 clearfix">
            <div class="col-sm-6 space_all">
              <div class="people_6">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Full Name"
                />
              </div>
            </div>
            <div class="col-sm-6 space_all">
              <div class="people_6">
                <input type="text" class="form-control" placeholder="Phone" />
              </div>
            </div>
          </div>
          <div class="people_7 clearfix">
            <div class="people_8">
              <textarea placeholder="Message" class="form-control"></textarea>
            </div>
          </div>
          <div class="people_9 clearfix">
            <div class="people_10">
              <a href="#" class="button_1">Donate</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 space_all">
        <div class="people_main clearfix">
          <div class="people_2">
            <a href="#"
              ><img src="img/cow.png" alt="abc" class="img_responsive"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="place">
    <div class="place_main clearfix">
      <div class="container">
        <div class="row">
          <div class="place_1">
            <h4>Our Volunteers</h4>
            <h2>Join Our Team</h2>
            <p>
              Kasd gubergre sea takimata sanctus est Lorem ipsum dolor sit amet.
              Lorem ipsum dolor sit amet,<br />
              consetetur sadipscing elitr, sed diam nonumy.
            </p>
            <a href="#" class="button_1">READ MORE</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="request">
    <div class="container">
      <div class="row">
        <div class="request_1 clearfix">
          <div class="col-sm-3">
            <div class="request_2">
              <a href="#"
                ><img src="img/team1.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
            <div class="request_3">
              <h3>Sed Augue</h3>
              <p>conubia</p>
              <ul>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="request_2">
              <a href="#"
                ><img src="img/team2.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
            <div class="request_3">
              <h3>Lorem Ipsum</h3>
              <p>cursus</p>
              <ul>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="request_2">
              <a href="#"
                ><img src="img/team3.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
            <div class="request_3">
              <h3>Integer</h3>
              <p>lacinia</p>
              <ul>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="request_2">
              <a href="#"
                ><img src="img/team4.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
            <div class="request_3">
              <h3>Eget Nulla</h3>
              <p>mauris</p>
              <ul>
                <li>
                  <a href="#"><i class="fa fa-facebook"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-twitter"></i></a>
                </li>
                <li>
                  <a href="#"><i class="fa fa-linkedin"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="testimonials">
    <div class="testimonials_1 clearfix">
      <div class="col-sm-6 space_all">
        <div class="testimonials_2">
          <a href="#"
            ><img src="img/inspire1.jpeg" alt="abc" class="img_responsive"
          /></a>
        </div>
      </div>
      <div class="col-sm-6 space_all">
        <div class="testimonials_main clearfix">
          <div class="testimonials_3">
            <h3>What inspires us?</h3>
            <h2><a href="#">Smiles are</a> true testimonials!</h2>
            <p>
              Dolor sit amet, consectetuer adipiscing elit diam, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore volutpat naomi
              delgado conseption guertena delbirkerin suit thelrio samdorano
              galactico.
            </p>

            <p>
              Dolor sit amet, consectetuer adipiscing elit diam, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore volutpat naomi
              delgado conseption guertena delbirkerin suit thelrio samdorano
              galactico.
            </p>
          </div>
          <div class="testimonials_4 clearfix">
            <div class="col-sm-6 space_all">
              <div class="testimonials_5">
                <h3><i class="fa fa-medkit"></i>Healthy Produce</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectuerter adipiscing elit
                  diam,<br />
                  sed diam nonummy nibh euismod
                </p>
              </div>
            </div>
            <div class="col-sm-6 space_all">
              <div class="testimonials_5">
                <h3><i class="fa fa-clock-o"></i>Garden Fresh Fruits</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectuerter adipiscing elit
                  diam,<br />
                  sed diam nonummy nibh euismod
                </p>
              </div>
            </div>
          </div>
          <div class="testimonials_4 clearfix">
            <div class="col-sm-6 space_all">
              <div class="testimonials_5">
                <h3><i class="fa fa-heart"></i>Biodiversity</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectuerter adipiscing elit
                  diam,<br />
                  sed diam nonummy nibh euismod
                </p>
              </div>
            </div>
            <div class="col-sm-6 space_all">
              <div class="testimonials_5">
                <h3><i class="fa fa-pagelines"></i>Soil Enrichment</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectuerter adipiscing elit
                  diam,<br />
                  sed diam nonummy nibh euismod
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="plant">
    <div class="plant_1 clearfix">
      <div class="col-sm-6 space_all">
        <div class="plant_2 clearfix">
          <div class="col-sm-6 space_all">
            <div class="plant_3">
              <a href="#"
                ><img src="img/inspire2.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
          </div>
          <div class="col-sm-6 space_all">
            <div class="plant_3">
              <a href="#"
                ><img src="img/inspire3.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
          </div>
        </div>
        <div class="plant_2 clearfix">
          <div class="col-sm-6 space_all">
            <div class="plant_3">
              <a href="#"
                ><img src="img/inspire4.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
          </div>
          <div class="col-sm-6 space_all">
            <div class="plant_3">
              <a href="#"
                ><img src="img/inspire5.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6 space_all">
        <div class="plant_4 clearfix">
          <div class="col-sm-8 space_all">
            <div class="plant_5">
              <a href="#"
                ><img src="img/inspire6.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
          </div>
          <div class="col-sm-4 space_all">
            <div class="plant_5">
              <a href="#"
                ><img src="img/inspire7.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
            <div class="plant_5">
              <a href="#"
                ><img src="img/inspire8.jpeg" alt="abc" class="img_responsive"
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <FooterItem />
</template>
<script>
import HeaderItem from '../components/HeaderItem.vue'
import FooterItem from '../components/FooterItem.vue'
export default {
  components: {
    HeaderItem,
    FooterItem
  }
}
</script>
