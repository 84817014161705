<template>
  <section id="footer">
    <div class="footer_inner clearfix">
      <div class="container">
        <div class="row">
          <div class="col-sm-12">
            <div class="col-sm-3">
              <div class="footer_1">
                <h3>AGRICULTURAL</h3>
                <p>
                  Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh
                  elementum imperdiet. Duis sagittis ipsum..Praesent mauris.
                  Fusce nec tellus
                </p>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="footer_2">
                <h3>Our Address</h3>
                <ul>
                  <li>
                    <i class="fa fa-map-marker"></i> Sed Dignissim Lacinia Nunc
                  </li>
                  <li><i class="fa fa-phone"></i> Phone: 0789326245</li>
                  <li>
                    <i class="fa fa-envelope"></i>
                    <a href="#">ganzatambaheritier24@gmail.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="footer_3">
                <h3>SEMPER PORTA</h3>
                <ul>
                  <li>
                    <a href="#"><i class="fa fa-facebook"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-twitter"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-google-plus"></i></a>
                  </li>
                  <li>
                    <a href="#"><i class="fa fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-sm-3">
              <div class="footer_2">
                <h3>VESTIBULUM</h3>
              </div>
              <div class="footer_4">
                <p>Enter your email and we’ll send you latest..</p>
              </div>
              <div class="footer_3">
                <p>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Your Email Address"
                  />
                </p>
                <a href="#" class="button">SIGN UP</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="footer_main" class="clearfix">
    <div class="col-sm-12">
      <div class="footer_main_1">
        <p>
          © 2023 Your Agriculture. All Rights Reserved. Design by<a
            href="https://portfolio-ganza.pages.dev/"
          >
            Heritier</a
          >
        </p>
      </div>
    </div>
  </section>
</template>
