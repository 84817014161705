<template>
  <section id="header_main">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 space_all">
          <div class="col-sm-3 space_all">
            <div class="header_main_1">
              <a href="/"><i class="fa fa-tree"></i>AGRICULTURAL</a>
            </div>
          </div>
          <div class="col-sm-9 space_all">
            <div class="header_main_2 clearfix">
              <div class="col-sm-4 space_all">
                <div class="header_main_3 clearfix">
                  <div class="col-sm-2">
                    <div class="header_main_4">
                      <i class="fa fa-map-marker"></i>
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="header_main_5">
                      <p>
                        153 Kicukiro Kigali <br />
                        Nyamata, NY 11334
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 space_all">
                <div class="header_main_3 clearfix">
                  <div class="col-sm-2">
                    <div class="header_main_4">
                      <i class="fa fa-mobile"></i>
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="header_main_5">
                      <p>
                        0789326245 <br />
                        <a href="#">info@organicfarming.com</a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sm-4 space_all">
                <div class="header_main_3 clearfix">
                  <div class="col-sm-2">
                    <div class="header_main_4">
                      <i class="fa fa-clock-o"></i>
                    </div>
                  </div>
                  <div class="col-sm-10">
                    <div class="header_main_5">
                      <p>
                        6 hours - 21 hours <br />
                        *Weekends working
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section id="header" class="cd-secondary-nav">
    <nav class="navbar navbar-default" role="navigation">
      <div class="container">
        <div class="navbar-header">
          <button
            type="button"
            class="navbar-toggle"
            data-toggle="collapse"
            data-target="#dropdown-thumbnail-preview"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <a class="navbar-brand" href="/"
            ><i class="fa fa-tree"></i>AGRICULTURAL</a
          >
        </div>
        <div class="collapse navbar-collapse" id="dropdown-thumbnail-preview">
          <ul class="nav navbar-nav">
            <li class="active"><a href="/">Home</a></li>
            <li><a href="/about">About</a></li>
            <li><a href="/services">Services</a></li>
            <li><a href="/shop">Shop</a></li>
            <li><a href="/shop-details">Shop Details</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/blog-details">Details</a></li>
            <li><a href="/gallery">Gallery</a></li>
            <li><a href="/contact">Contact</a></li>
            <ul class="nav navbar-nav">
              <li class="dropdown dropdown-large">
                <a href="#" class="hvr-pulse" data-toggle="dropdown"
                  >Dropdown <b class="caret"></b
                ></a>
                <ul class="dropdown-menu dropdown-menu-large row">
                  <div class="col-sm-4">
                    <div class="header_4">
                      <div class="ih-item square effect14 top_to_bottom">
                        <a href="#">
                          <div class="img">
                            <img src="img/home1.jpeg" alt="img" />
                          </div>
                          <div class="info">
                            <h3>Heading here</h3>
                            <p>
                              dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the when an unknown
                              printer took a galley of type
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="header_4">
                      <div class="ih-item square effect14 top_to_bottom">
                        <a href="#">
                          <div class="img">
                            <img src="img/home2.jpeg" alt="img" />
                          </div>
                          <div class="info">
                            <h3>Heading here</h3>
                            <p>
                              dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the when an unknown
                              printer took a galley of type
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-4">
                    <div class="header_4">
                      <div class="ih-item square effect14 top_to_bottom">
                        <a href="#">
                          <div class="img">
                            <img src="img/home3.jpeg" alt="img" />
                          </div>
                          <div class="info">
                            <h3>Heading here</h3>
                            <p>
                              dummy text of the printing and typesetting
                              industry. Lorem Ipsum has been the industry's
                              standard dummy text ever since the when an unknown
                              printer took a galley of type
                            </p>
                          </div>
                        </a>
                      </div>
                    </div>
                  </div>
                </ul>
              </li>
            </ul>
          </ul>
        </div>
      </div>
    </nav>
  </section>
</template>
